.wrapper {
  height: 100vh;
  background-color: #DC2268;
  border-bottom: 1px solid #EB578F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 90px;
    color: #fff;
    font-family: Inter;
  }
  
  p {
    font-size: 24px;
    color: #fff;
    margin-top: 3px;
    margin-bottom: 70px;
  }
}