.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .thumbnailWrapper {
    height: 580px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    &:hover img {
      transform: scale(1.1);
      transition: 0.1s;
    }

    img {
      transition: 0.3s;
      transform: scale(1);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tags {
    display: flex;
    margin: 20px 0;
    align-items: center;
    gap: 20px;

    .tag {
      align-items: center;
      display: flex;
      gap: 6px;

      .infoText {
        color: #000;
        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }

  .underlineAdder {
    position: relative;
    cursor: pointer;

    &:hover .underline {
      width: 100%;
      transition: 0.1s;
    }

    .title {
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
      color: #000;
      font-family: Inter;
      font-size: 17.5px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .underline {
      z-index: -1;
      position: absolute;
      bottom: 1px;
      transition: 0.3s;
      width: 0px;
      height: 2px;
      background-color: #000;
    }
  }
}
