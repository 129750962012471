.wrapper {
  padding: 0 192px 44px 192px;
  background-color: #dc2268;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 42px 0 32px 0;
    border-bottom: 1px solid #fff;

    h2 {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 37.406px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 128.321% */
      margin-bottom: 40px;
    }

    .inputWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 64px;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        padding-left: 20px;

        color: #000000;
        font-family: Inter;
        font-size: 12.573px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .enterButton {
        position: absolute;
        right: 16px;
        top: 14px;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 30px 0;
    .left {
      width: 60%;
      .footerLogo {
        margin-bottom: 6px;
      }
      p {
        color: #fff;
        font-family: Varela;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 16px;
      }

      .socials {
        display: flex;
        gap: 35px;

        svg {
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      gap: 50px;
      justify-content: space-between;
      max-width: 460px;
      min-width: 254px;
      flex-grow: 1;
      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        p {
          color: #fff;
          font-family: Lato;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 138.462% */
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-bottom: 11px;
        }

        .underlineAdder {
          position: relative;
          &:hover .underline {
            width: 100%;
            transition: 0.1s;
          }
          
          a {
            text-decoration: none;
            color: #fff;
            font-family: Varela;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            
          }

          .underline {
            transition: 0.3s;
            width: 0;
            height: 1px;
            bottom: 1px;
            position: absolute;
            background-color: #fff;
          }
          
        }
      }
    }
  }
}
.rightsReserved {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #606060;
  text-align: center;
  font-family: Varela;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px; /* 160% */
}


@media screen and (max-width:1080px) {
  .wrapper {

    padding: 20px;
    .top {

      h2 {
        font-size: 24px;
        line-height: 30px;
      }

      .inputWrapper {
        width: 100%;
      }
    }
    
    .bottom {
      align-items: center;
      
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }

        .socials {
          justify-content: center;
        }
      }

      .right {
        width: 100%;
      }
      flex-direction: column;
      align-items: center;

      gap: 30px;
    }
  }
}