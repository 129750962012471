* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}



@font-face {
  font-family: "Varela";
  src: url("./assets/fonts/Varela-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rimouski";
  src: url("./assets/fonts/rimouski sb.otf") format("opentype");
}
@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("opentype");
  box-sizing: border-box;
}

h1 {
  color: #000;
  font-family: Inter;
  font-size: 54.375px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 132.414% */
}

h2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h3 {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 150% */
}

p {
  color: #000;
  font-family: Varela;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
}

