.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .heroImg {
    width: 100%;
    object-fit: cover;
    height: 750px;
    margin-bottom: 51px;
  }

  h1 {
    max-width: 90vw;
    width: 1200px;
    padding: 0 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 62.156px;
    font-style: normal;
    font-weight: 600;
    line-height: 68px; /* 193.062% */
  }

  p {
    max-width: 90vw;
    width: 1200px;
    padding: 0 20px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16.594px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px; /* 151.864% */
    letter-spacing: 0.36px;
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 90px;
    margin-bottom: 145px;
    color: #7be56f;
    text-align: center;
    font-family: Inter;
    font-size: 41.797px;
    font-style: normal;
    font-weight: 600;
    line-height: 75px; /* 179.439% */
  }

  .contactStuff {
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    margin-bottom: 134px;
    border-top: 1px solid #a0a0a0;
    border-bottom: 1px solid #a0a0a0;
    width: 1500px;
    max-width: 90vw;

    .side {
      display: flex;
      justify-content: center;
      gap: 70px;

      .text {
        width: 280px;
        h5 {
          color: #000;
          font-family: Inter;
          font-size: 42.938px;
          font-style: normal;
          font-weight: 600;
          line-height: 57.6px; /* 134.148% */
        }

        p {
          width: unset;
          padding: 0;
          color: #4d4d4d;
          font-family: Inter;
          font-size: 14.875px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.4px; /* 150.588% */
          letter-spacing: 0.32px;
          margin: 19px 0;
          text-align: left;
        }

        a {
          text-decoration: none;
          margin-bottom: -1px;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #000;
          text-align: center;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 25.2px; /* 140% */
          letter-spacing: 0.36px;
          text-transform: uppercase;
          transition: 0.3s;

          &:hover {
            gap: 12px;
            transition: 0.1s;
          }

          .button {
            display: flex;
            width: 25px;
            height: 25px;
            padding: 4px 0px;
            justify-content: center;
            align-items: center;

            border-radius: 25px;
            background: #87e874;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1187px) {
  .side {
    flex-grow: 1;
  }
}


@media screen and (max-width:1080px) {
  .wrapper {
    margin-top: 60px;
    .heroImg {
      height: 200px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 50px;
      line-height: 55px;
    }
    
    h2 {
      max-width: none;
      width: 100%;
    }
    p {
      max-width: none;
      width: 100%;
    }

    h3 {
      margin-top: 60px;
      margin-bottom: 90px;
    }

    .contactStuff {

      .side {
        gap: 20px;

        svg {
          flex-shrink: 1;
          width: 30%;
        }

        .text {
          flex-grow: 1;
          width: 65%;
        }
      }
    }
  }
}