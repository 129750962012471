.wrapper {
  
  height: 960px;
  display: flex;

  .left {
    width: 50%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 550px;
    
      h2 {
        color: #000;
        font-family: Inter;
        font-size: 43.688px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        span {
          color: #DC2268;
        }
      }

      p {
        margin-top: 32px;
        font-size: 16px;
        margin-bottom: 16px;
      }

      .input {
        resize: none;
        margin-bottom: 25px;
        padding: 17px 21px;
        width: 100%;
        border: 1px solid #E0E0E0;
        background: #FFF;
        color: #000;
        font-family: Inter;
        font-size: 13.672px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
      }

      button {
        padding: 16px 0;
        width: 100%;
        transition-duration: 0.3s;
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          transition: 0.1s;
          background-color: #EB578F !important;
        }
      }
    }
  }

  .right {
    width: 50%;
    position: relative;
    overflow: hidden;
    background-color: #7BE56F;

    img {
      position: absolute;
      top: 80px;
      width: 550px;
      height: 640px;
      border-radius: 10px;
      object-fit: cover;
      z-index: 1;
    }

    .whiteBox {
      width: 288px;
      position: absolute;
      height: 100%;
      z-index: 0;
      left: 0;
      background-color: #fff;
    }

  }
}

@media screen and (max-width:1080px) {
  .wrapper {
    margin-top: 80px;
    flex-direction: column;
    
    .left {
      width: 100%;
    }

    .right {
      height: 312px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: unset;
        width: 90%;
        height: 90%;
      }
    }

    .whiteBox {
      display: none;
    }
  }
}