.wrapper {
  display: flex;
  justify-content: center;
  padding: 50px;
  .content {
    width: 100%;
    max-width: 1532px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    h2 {
      color: #000;
      font-family: Inter;
      margin-top: 0;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px;
    }

    h3 {
      font-family: Inter;
      margin: 20px 0;
    }

    p {
      margin-left: 20px;
      font-family: Inter;
    }
  }
}