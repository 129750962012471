.wrapper {
  z-index: 999999;
  //position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  padding: 10px 320px;
  border-bottom: 1px solid #868686;
  background: #fff;
  gap: 70px;

  .logo {
    flex-shrink: 0;
    position: absolute;
    left: 3%;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    margin-bottom: -2px;
  }

  .dropdown {
    cursor: context-menu;
    z-index: 99999999;
    max-width: 150px;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: #000;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 5px 0;
    border-radius: 8px;
    transition: 0.3s;
    margin-top: 2px;
    
    .dropdownArrow {
      pointer-events: none;
      margin-left: 8px;
      margin-top: 2px;
    }
    
    .dropdownMenu {
      position: absolute;
      top: 20px;
      overflow: hidden;
      transition: height 0.4s ease;
      border-radius: 10px;
      
      .hitbox {
        width: 100%;
        height: 40px;
      }
      
      .options {
        cursor: pointer;
        background-color: #fff;
        width: 180px;
        border-radius: 10px;
        overflow: hidden;

        .dropdownItem {
          cursor: pointer;
          display: flex;
          gap: 16px;
          padding: 11px 16px;
          align-items: center;
          transition: 0.1s;
          justify-content: flex-start;
          padding-right: 30px;
          position: relative;
          
          p {
            text-align: left;
            margin-top: -2px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            pointer-events: none;

          }

          &:before {
            content: "";
            position: absolute;
            left: 5%;
            bottom: 0px;
            height: 1px;
            width: 90%; /* or 100px */
            background: #000;
          }

          img {
            pointer-events: none;
            width: 20px;
            height: 20px;
          }

          &:hover {
            background: rgba(251, 214, 218, 0.5);
            border-left: 2px solid #eb57bf;
          }


        }
      }
    }
  }
  .option {
    cursor: pointer;
    text-align: center;
    color: #000;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    text-transform: uppercase;
    max-width: 150px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 5px 0;
    border-radius: 8px;
    transition: 0.3s;
    flex-grow: 1;

    &:hover {
      transition: 0.1s;
      background: #fbd6da65;
    }
  }
  .selectedOption {
    padding: 5px 0;
    min-width: 100px;
    flex-grow: 1;
    text-align: center;
    color: #000;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    text-transform: uppercase;
    max-width: 175px;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #fbd6da;
  }

  .shelfIcon {
    width: 24px;
    height: 18px;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    display: none;

    .line {
      background-color: #DC2268;
      border-radius: 100px;
      width: 100%;
      height: 2px;
    }
  }

  .shelf {
    position: absolute;
    top: 60px;
    width: 100%;
    height:calc(100dvh - 60px);
    transition: height 0.3s;
    overflow-y: hidden;
    background-color: #DC2268;
    display: flex;
    flex-direction: column;

    a {
      padding: 20px 0;
      color: #fff;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      text-decoration: none;
      font-weight: 400;
      line-height: 150%;
      width: 100%;
      display: flex;
      justify-content: center;
      min-width: 90px;
      text-transform: uppercase;
      border-bottom: 1px solid #0000001a;
      cursor: pointer;
      transition: 0.3s;
      
      &:hover {
        background-color: #fff;
        color: #DC2268;
        transition: 0.1s;
      }
    }

  }
}

@media screen and (max-width:1080px) {
  .wrapper {
    position: fixed;
    height: 60px;
    padding: 10px 0;

    .logo {
      width: 150px;
    }

    .option {
      display: none;
    }

    .selectedOption {
      display: none;
    }

    .dropdown {
      display: none;
    }


    .dropdown {
      width: 60px !important;
      min-width: 60px !important;
      right: 70px !important;

      .options {
        width: 100px !important;
      }
    }
    .shelfIcon {
      display: flex;
    }
  }
}