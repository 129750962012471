.wrapper {
  .section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 30px 0 30px;

    .content {
      width: 100%;
      max-width: 1512px;
      flex-grow: 1;
      h2 {
        color: #000;
        font-family: Inter;
        font-size: 39.375px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        margin-bottom: 50px;
      }

      .position {
        border-bottom: 1px solid #212121;
        padding: 24px 0 40px 0;
        h3 {
          margin-bottom: 14px;
          text-align: left;
          color: #192227;
          font-family: Inter;
          font-size: 22.875px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &:last-of-type {
          border: 0 !important;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          row-gap: 10px;
          margin-bottom: 18px;

          .tag {
            text-align: center;
            flex-shrink: 0;
            padding: 4px 16px;
            background-color: #00864a;
            border-radius: 100px;
            color: #fff;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .description {
          color: #192227;
          font-family: Inter;
          font-size: 16.734px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px; /* 161.345% */
          margin-bottom: 18px;
        }

        button {
          border-radius: 40px;
          border: 1px solid #7e7e7e;
          background: #fff;
          backdrop-filter: blur(12.5px);
          padding: 17px 57px;
          cursor: pointer;
          color: #000;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          transition: 0.3s;

          &:hover {
            transition: 0.1s;
            background-color: #e3e3e3;
          }
        }
      }
    }
  }

  .section2 {
    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }
    .content {
      padding: 40px 190px 50px 190px;

      .faq {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 30px;
        column-gap: 77px;

        .shelfWrapper {
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          width: 700px;
          padding: 0 32px;
          overflow: hidden;
          height: 82px;
          transition: 0.3s;
          min-height: 64px;
          
          .question {
            cursor: pointer;
            min-height: 64px;
            display: flex;
            align-items: center;
            gap: 10%;
            justify-content: space-between;

            svg {
              width: 25px;
              height: 25px;
              flex-shrink: 0;
            }

            p {
              height: 100%;
              color: #000;
              font-family: Varela;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 200% */
            }
          }

          .answer {
            padding-top: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            color: #525252;
            font-family: Varela;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    margin-top: 60px;
    .section2 {
      .content {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 748px) {
    .wrapper {
      margin-top: 60px;
      .section2 {
        .content {
          padding: 20px;

          .faq {
            .shelfWrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
