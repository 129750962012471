.wrapper {
  padding: 27px 192px 134px;

  h2 {
    text-align: left;
    color: #000;
    font-family: Inter;
    font-size: 44.438px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 80px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  h6 {
    color: #000;
    font-family: Inter;
    font-size: 23.516px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin-bottom: 32px;
  }

  ol,ul {
    margin-left: 20px;
    margin-bottom: 60px;
    li {
      margin-bottom: 5px;
      color: #000;
      font-family: Inter;
      font-size: 18.125px;
      font-style: normal;
      font-weight: 200;
      line-height: 30px; /* 165.517% */
      text-align: left;
    } 
  }

  p {
    color: #000;
    font-family: Inter;
    font-size: 18.125px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px; /* 165.517% */
    text-align: left;
    margin-bottom: 60px;
  }

  .applyWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 160px;

    .content {
      width: 550px;

      h2 {
        margin-bottom: 33px;
        color: #000;
        font-family: Inter;
        font-size: 40.875px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        span {
          color: #dc2268;
          font-family: Inter;
          font-size: 40.875px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          margin-bottom: 18px;
          color: #000;
          font-family: Varela;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }

      .entriesWrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .entry {
          width: 100%;
          padding: 17px 21px;
          border: 1px solid #e0e0e0;
          background: #fff;

          color: #000;
          font-family: Inter;
          font-size: 13.672px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.14px;
        }
        .fileUploadInfo {
          margin: 16px 0;

          color: #000;
          font-family: Inter;
          font-size: 13.875px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        button {
          background: #dc2268;
          padding: 15px 0;
          cursor: pointer;

          color: #fff;
          text-align: center;
          font-family: Lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          transition: 0.3s;

          &:hover {
            transition: 0.1s;
            background-color: #eb578f;
          }
          
          &:disabled {
            cursor: wait;
            background-color: #e788ac;
          }
        }
      }
    }
  }
}

@media screen and (max-width:1080px) {

  .wrapper {
    margin-top: 60px;
    padding: 20px;
    
    h2 {
      margin-bottom: 30px;
    }
    
    h6 {
      margin-bottom: 15px;
  }
  
  p {
    margin-bottom: 20px;
  }
}
}