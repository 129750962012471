.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  ol,ul {
    margin-left: 30px;

    li {
      color: #000;
      font-family: Varela;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px; /* 150% */
    }
  }


  .content {
    width: 100%;
    max-width: 1535px;

    .titleAndInfo {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 82px;
      }

      .tags {
        display: flex;
        gap: 50px;
        margin-bottom: 100px;

        p {
          display: flex;
          gap: 10px;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px; /* 150% */
          text-transform: uppercase;
          span {
            font-weight: 700;
            color: #7BE56F;
          }
        }
      }
    }

    .information {
      display: flex;
      margin-bottom: 148px;
      .left {
        border-right: 1px solid #B9B9B9;
        width: 100%;
        max-width: 568px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 60px 0 0;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        
        h3 {
          margin-bottom: 20px;
          color: #7BE56F;
          font-family: Lato;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px; /* 150% */
          text-transform: uppercase;
        }

        .notesLine {
          flex-shrink: 1;
          color: #000;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px; /* 150% */
        }

        .ingredientText {
          margin-top: 132px;
        }

        .ingredient {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          gap: 5px;
          margin-left: 24px;
          .dot {
            width: 6px;
            height: 6px;
            border-radius: 100px;
            background-color: #000;
          }
        }
      }
      
      .right {
        width: 100%;
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        img {
          width: 100%;
          height: 480px;
          object-fit: cover;
          border-radius: 15px;
          margin-bottom: 132px;
        }

        h3 {
          color: #7BE56F;
          font-family: Lato;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px; /* 150% */
          text-transform: uppercase;
        }
      }
    }

    h4 {
      color: #000;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
    .moreRecipes {
      overflow-x: scroll;
      margin-top: 32px;
      display: flex;
      gap: 42px;

      .newRecipeWrapper {
        min-width: 300px;
        width: 100%;
        padding-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width:1080px) {
  .wrapper {
    padding: 50px 20px 20px 20px;
    margin-top: 60px;
    .content {

      .titleAndInfo {

        h2 {
          margin-bottom: 40px;
        }

        .tags {
          justify-content: center;
          column-gap: 15px;
          row-gap: 10px;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
      }

      .information {
        flex-direction: column;
        margin-bottom: 50px;

        .left {
          padding-right: 0;
          border-right: none;
          padding-bottom: 20px;

          .ingredientText {
            margin-top: 40px;
          }

          .ingredient {
            margin-left: 10px;
          }
        }

        .right {
          width: 100%;
          padding-left: 0;

          img {
            margin-bottom: 30px;
          }

          h3 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}