.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 150px 100px 150px;
  h2 {
    margin: 55px 0 36px 0;
    text-align: left;
  }
}


@media screen and (max-width:1080px) {
  .wrapper {
    margin-top: 60px;
    padding: 0 20px 20px 20px;
  }
}