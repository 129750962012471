.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: calc(100vh - 70px);
    max-height: 1080px;

    img {
      z-index: -1;
      height: 100%;
      position: absolute;
      width: 100%;
      object-fit: cover;
    }

    h2 {
      max-width: 1100px;
      padding: 20px;
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 92.969px;
      font-style: normal;
      font-weight: 600;
      line-height: 100px; /* 107.563% */
    }
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 43.125px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 94px;
    margin-bottom: 127px;
  }

  .recipesWrapper {
    width: 100%;
    max-width: 1622px;
    padding: 0 50px;
    display: flex;
    row-gap: 60px;
    column-gap: 35px;
    flex-wrap: wrap;
  }

  h5 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 29.75px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 150px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width:1621px) {
  .recipesWrapper {
    max-width:1103px !important;
  }
}

@media screen and (max-width:1102px) {
  .recipesWrapper {
    max-width:484px !important;
    padding: 0 !important;
  }
}

@media screen and (max-width:1080px) {
  .wrapper {
    margin-top: 60px;
    .hero {
      
      h2 {
        font-size: 50px;
        line-height: 50px;
      }
    }

    h3 {
      margin-bottom: 80px;
      margin-top: 60px;
      font-size: 38px;
    }

    .recipesWrapper {
      padding: 0 20px !important;
    }

    h5 {
      margin-top: 100px;
    }
  }
}