.errorWrapper {
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 100000000;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dc2268;

  h1 {
    color: #fff;
  }
  
  h2 {
    color: #fff;
    
  }
  
  svg {
    width: 400px;
    height: 400px;
  }
}