.screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  width: 100%;
  img {
    width: 250px;
    height: auto !important;
  }
  h1 {
    font-size: 180px;
    line-height: unset !important;
    text-transform: uppercase;
    text-align: center;
    color: rgba($color: #fff, $alpha: 1);
    
    @media screen and (max-width: 1440px) {
      font-size: 120px;
    }
    @media screen and (max-width: 768px) {
      font-size: 42px;
    }
  }
  h2{
    line-height: unset !important;
    text-transform: uppercase;
    text-align: center;
    color: rgba($color: #fff, $alpha: 1);
  }
  h3 {
    line-height: unset !important;
    font-size: 42px;
    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    text-transform: uppercase;
    color: rgba($color: #fff, $alpha: 0.6);
  }
  h6 {
    margin-bottom: 15px;
    text-align: center;
  font-family: Lato;
    line-height: unset !important;
    font-size: 32px;
    @media screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    text-transform: uppercase;
    color: rgba($color: #fff, $alpha: 0.6);
  }
}
