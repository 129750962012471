.wrapper {
  width: 750px;

  .thumbnailWrapper {
    width: 100%;
    height: 700px;
    flex-shrink: 0;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 16px;
    cursor: pointer;

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;

      &:hover {
        transition: 0.2s;
        transform: scale(1.15);
      }
    }
  }
  .textContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .poster {
      display: flex;
      align-items: center;
      gap: 18px;
      
      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100px;
      }
      
      .dot {
        width: 2px;
        height: 2px;
        border-radius: 100px;
        background-color: #3592ff;
        margin-right: -14px;
      }

      p {
        color: #494949;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      
      a {
        color: #3592ff;
        font-family: Inter;
        font-size: 14.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 165.517% */
        text-decoration: none;
        margin-bottom: -1px; //corrects some misalignment
      }
    }

    .titleWrapper {
      position: relative;
      margin-top: 26px;
      
      h3 {
        text-align: left;
        cursor: pointer;
        color: #000;
        font-family: Inter;
        font-size: 28.375px;
        font-style: normal;
        font-weight: 600;
        line-height: 38.4px; /* 135.33% */
        text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
        &:hover + .underline {
            width: 100%;
            transition: 0.1s;
        }
      }

      .underline {
        transition: 0.3s;
        position: absolute;
        bottom: 3px;
        z-index: -1;
        width: 0px;
        height: 3px;
        background-color: #000;
      }
    }

    .divider {
      margin-top: 21px;
      width: 116px;
      height: 10px;
      flex-shrink: 0;
      background-color: #dc2268;
    }

    .text {
      margin-top: 21px;
      color: #000;
      font-family: Varela;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      .span1 {
        opacity: 0.75;
      }
      .span2 {
        opacity: 0.6;
      }
      .span3 {
        opacity: 0.5;
      }
      .span4 {
        opacity: 0.4;
      }
      .span5 {
        opacity: 0.3;
      }
      .span6 {
        opacity: 0.2;
      }
    }

    .readMoreWrapper {
      cursor: pointer;
      margin-top: 40px;
      gap: 10px;
      display: flex;
      align-items: center;
      transition: 0.3s;

      &:hover {
        transition: 0.1s;
        gap: 20px;
      }

      p {
        color: #000;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px; /* 140% */
        letter-spacing: 0.32px;
        text-transform: uppercase;
      }

      svg {
        width: 24px;
        height: 14px;
      }
    }
  }
}


@media screen and (max-width:1080px) {

  .wrapper {
    width: 100%;
    margin-top: 60px;
  }
}