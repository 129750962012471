.menuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heroSection {
  background: #00864a;
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: row;
  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 32px;

      max-width: 490px;

      .bigText {
        color: #fff;
        font-family: Rimouski;
        font-size: 60px;
        font-weight: 300;
        line-height: 72px; /* 120% */
      }
      .subTitle {
        color: #fff;
        font-family: Varela;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px; /* 150% */
      }
    }
  }
  .right {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.menuSection {
  display: flex;
  width: 100%;
  padding: 40px 79px 40px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  .categoryFilters {
    z-index: 5;

    position: sticky;
    top: 10px;
    display: flex;
    height: 100%;
    padding: 18px 5%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    background: #fff;
    box-shadow: 6px 9px 24px 0px rgba(0, 0, 0, 0.07);
    flex-wrap: wrap;
    .categoryButton {
      display: flex;
      padding: 11px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 40px;
      background: rgba($color: #00864a, $alpha: 0.1);
      transition: all 0.3s ease;
      color: #00864a;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
      text-transform: uppercase;
      width: fit-content;
      &:hover {
        background-color: #00864a;
        color: white;
      }

      &.active {
        background-color: #00864a;
        color: white;
      }
    }
  }
}

.foodTypesFilter {
  z-index: 5;
  position: sticky;
  top: 110px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  min-width: 170px;
  @media screen and (max-width: 1440px) {
    min-width: 152px;
  }

  h2 {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
  }

  .typeFilterItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    .foodTypeOption {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 18px;
      color: #7e7e7e;
      font-family: Lato;

      input[type="checkbox"] {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 2px solid #dc2268;
        cursor: pointer;
        appearance: none;
        background-color: transparent;
        transition: background-color 0.3s, border-color 0.3s;

        &:checked {
          background-color: #dc2268;
          border-color: #dc2268;

          + span {
            opacity: 1;
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
        }
      }

      span {
        color: #000;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: uppercase;
        opacity: 0.5;
        transition: color 0.3s; /* Smooth transition for color change */
      }

      &.active {
        span {
          opacity: 1 !important;
        }
      }
    }
  }
}

.menuItemsWithTypes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4.37%;
  width: 100%;
}
.menuItems,
.skeletonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.menuItem {
  z-index: 1;
  text-decoration: none;
  display: flex;
  width: 338px;
  align-self: stretch;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border: 1px solid #ecece5;
  background: #fff;
  transition: all 0.3s;
  &:hover {
    .itemImage {
      img {
        transition: all 0.3s;

        scale: 1.1;
      }
    }
  }
  .itemImage {
    transition: all 0.3s;

    width: 180px;
    height: 180px;
    img {
      transition: all 0.5s;

      width: 100%;
      height: auto;
    }
  }
  .itemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    height: fit-content;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      h4 {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 150% */
        text-transform: uppercase;
      }
      .block {
        width: 110px;
        height: 10px;
        background: #dc2268;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      font-family: Varela;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
    }
    span {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
      text-transform: uppercase;
    }
  }
}

.skeleton {
  .itemImageSkeleton {
    width: 180px;
    height: 180px;
    background-color: #e0e0e0;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
  }

  .titleSkeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .titleBlockSkeleton {
      width: 60%;
      height: 27px;
      background-color: #e0e0e0;
      border-radius: 5px;
      animation: pulse 1.5s infinite;
    }

    .blockSkeleton {
      width: 110px;
      height: 10px;
      background-color: #e0e0e0;
      animation: pulse 1.5s infinite;
      border-radius: 5px;
    }
  }

  .descriptionSkeleton {
    width: 80%;
    height: 21px;
    background-color: #e0e0e0;
    animation: pulse 1.5s infinite;
    border-radius: 5px;
  }

  .priceSkeleton {
    width: 40%;
    height: 30px;
    background-color: #e0e0e0;
    animation: pulse 1.5s infinite;
    border-radius: 5px;
  }
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
